<template>
    <div class="login-page">
        <div class="login-bg"></div>
        <div class="login-box">
            <div class="logo">
                <!-- <img src="@/assets/img/logo2.png" /> -->
                <p>心安生命管理后台</p>
            </div>
            <div class="form">
                <el-form :model="formData" :rules="rules" ref="formData" hide-required-asterisk @submit.native.prevent="submitForm">
                    <el-form-item prop="telephone">
                        <el-input v-model="formData.telephone" placeholder="请输入您的电话号码">
                            <i slot="prefix" class="el-icon-user"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="pw">
                        <el-input type="password" v-model="formData.pw" placeholder="请输入您的登录密码">
                            <i slot="prefix" class="el-icon-key"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item class="btn-box">
                        <el-button type="primary" @click="submitForm()" :loading="loginLoading" native-type="submit">登 录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
// var md5 = require('js-md5');
export default {
    name: "loginPage",
    data() {
        return {
            formData: {
                telephone: "",
                pw:""
            },
            rules: {
                telephone: [{ required: true, message: "请输入您的电话号码" },{ validator: this.$utils.validatePhone }],
                pw: [{ required: true, message: "请输入您的登录密码" }]
            },
            loginLoading: false,
        };
    },
    created() {
        
    },
    methods: {
        //提交表单
        submitForm() {
            let self = this;
            self.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.loginLoading = true;
                    this.$request({
                        url: "/api/ht/user/login",
                        method: "POST",
                        params: {
                            ...this.formData,
                            // pw:md5(this.formData.pw).toLowerCase()
                        }
                    }).then( res => {
                        const { state, errmsg, result } = res.data;
                        if (state && result) {
                            const redirect = self.$route.query.redirect || '/';

                            // self.$store.commit( 'setToken', data.token || null );
                            self.$store.commit('setUserInfo', result.name && {name: result.name,id: result.id} || null);
                            self.$store.commit( 'setMenuList', result.privileges.menuList || [] );

                            self.$router.push(redirect);
                            
                        } else {
                            this.$message.error(errmsg || "请求失败");
                        }
                    }).catch(() => {
                        this.$message.error('请求错误');
                    }).finally( () => {
                        this.loginLoading = false;
                    });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@keyframes scale{
	0% {
		transform: scale(1);
		// opacity:.5;
	}
	50% {
		transform: scale(1.2);
		opacity:1;
	}
	100% {
		transform: scale(1);
		// opacity:.5;
	}
}
.login-page{
    height: 100vh;
    position: relative;
    overflow: hidden;
    .login-bg{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url('~@/assets/img/login-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        transform: scale(1);
		animation: scale 30s 0s linear infinite;
        // background-color:rgba(0, 0, 0, 0.80);
        // filter: blur(80px);
        // background: radial-gradient(circle at center, #f9e4ce, #efc69c, #c99c6d);
    }
    @media screen and (max-width: $--screen-sm) {
        background: transparent !important;
    }
    .login-box{
        background: rgba(0, 0, 0, 0.70);
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translateY(-50%);
        padding: 50px;
        width: 440px;
        margin-right: -220px;
        border-radius: 8px;
        box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.30);
        .logo{
            // width: 188px;
            margin-bottom: 30px;
            text-align: center;
            font-size: 20px;
            color: #fff;
            img{
                width: 70px;
                // background-color: $--color-theme;
                // border-radius: 8px;
            }
            p{
                margin-top: 5px;
            }
        }
        .copyright{
            margin-top: 20px;
            font-size: 10px;
            color: $--color-font-gray;
            text-align: center;
        }
        // .el-input{
        //     ::v-deep .el-input__inner{
        //         background-color: transparent;
        //         box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.70) inset;
        //         color: #ffffff;
        //     }
        // }
        .el-button{
            width: 100%;
        }
        .btn-box{
            margin-top: 40px;
            .el-button{
                height: 46px;
            }
        }
        @media screen and (max-width: $--screen-sm) {
            width: 100%;
            padding: 20px;
            right: 0;
            margin-right: 0;
        }
    }
}
</style>